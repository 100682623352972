const WBGT40 = { 20: 29, 25: 30, 30: 31, 35: 32, 40: 33, 45: 34, 50: 35, 55: 35, 60: 36, 65: 37, 70: 38, 75: 39, 80: 40, 85: 41, 90: 42, 95: 43, 100: 44 }
const WBGT39 = { 20: 28, 25: 29, 30: 30, 35: 31, 40: 32, 45: 33, 50: 34, 55: 35, 60: 35, 65: 36, 70: 37, 75: 38, 80: 39, 85: 40, 90: 41, 95: 42, 100: 43 }
const WBGT38 = { 20: 28, 25: 28, 30: 29, 35: 30, 40: 31, 45: 32, 50: 33, 55: 34, 60: 35, 65: 35, 70: 36, 75: 37, 80: 38, 85: 39, 90: 40, 95: 41, 100: 42 }
const WBGT37 = { 20: 27, 25: 28, 30: 29, 35: 29, 40: 30, 45: 31, 50: 32, 55: 33, 60: 34, 65: 35, 70: 35, 75: 36, 80: 37, 85: 38, 90: 39, 95: 40, 100: 41 }
const WBGT36 = { 20: 26, 25: 27, 30: 28, 35: 29, 40: 29, 45: 30, 50: 31, 55: 32, 60: 33, 65: 34, 70: 34, 75: 35, 80: 36, 85: 37, 90: 38, 95: 39, 100: 39 }
const WBGT35 = { 20: 25, 25: 26, 30: 27, 35: 28, 40: 29, 45: 29, 50: 30, 55: 31, 60: 32, 65: 33, 70: 33, 75: 34, 80: 35, 85: 36, 90: 37, 95: 38, 100: 38 }
const WBGT34 = { 20: 25, 25: 25, 30: 26, 35: 27, 40: 28, 45: 29, 50: 29, 55: 30, 60: 31, 65: 32, 70: 33, 75: 33, 80: 34, 85: 35, 90: 36, 95: 37, 100: 37 }
const WBGT33 = { 20: 24, 25: 25, 30: 25, 35: 26, 40: 27, 45: 28, 50: 28, 55: 29, 60: 30, 65: 31, 70: 32, 75: 32, 80: 33, 85: 34, 90: 35, 95: 35, 100: 36 }
const WBGT32 = { 20: 23, 25: 24, 30: 25, 35: 25, 40: 26, 45: 27, 50: 28, 55: 28, 60: 29, 65: 30, 70: 31, 75: 31, 80: 32, 85: 33, 90: 34, 95: 34, 100: 35 }
const WBGT31 = { 20: 22, 25: 23, 30: 24, 35: 24, 40: 25, 45: 26, 50: 27, 55: 27, 60: 28, 65: 29, 70: 30, 75: 30, 80: 31, 85: 32, 90: 33, 95: 33, 100: 34 }
const WBGT30 = { 20: 21, 25: 22, 30: 23, 35: 24, 40: 24, 45: 25, 50: 26, 55: 27, 60: 27, 65: 28, 70: 29, 75: 29, 80: 30, 85: 31, 90: 32, 95: 32, 100: 33 }
const WBGT29 = { 20: 21, 25: 21, 30: 22, 35: 23, 40: 24, 45: 24, 50: 25, 55: 26, 60: 26, 65: 27, 70: 28, 75: 29, 80: 29, 85: 30, 90: 31, 95: 31, 100: 32 }
const WBGT28 = { 20: 20, 25: 21, 30: 21, 35: 22, 40: 23, 45: 23, 50: 24, 55: 25, 60: 25, 65: 26, 70: 27, 75: 28, 80: 28, 85: 29, 90: 30, 95: 30, 100: 31 }
const WBGT27 = { 20: 19, 25: 20, 30: 21, 35: 21, 40: 22, 45: 23, 50: 23, 55: 24, 60: 25, 65: 25, 70: 26, 75: 27, 80: 27, 85: 28, 90: 29, 95: 29, 100: 30 }
const WBGT26 = { 20: 18, 25: 19, 30: 20, 35: 20, 40: 21, 45: 22, 50: 22, 55: 23, 60: 24, 65: 24, 70: 25, 75: 26, 80: 26, 85: 27, 90: 28, 95: 28, 100: 29 }
const WBGT25 = { 20: 18, 25: 18, 30: 19, 35: 20, 40: 20, 45: 21, 50: 22, 55: 22, 60: 23, 65: 23, 70: 24, 75: 25, 80: 25, 85: 26, 90: 27, 95: 27, 100: 28 }
const WBGT24 = { 20: 17, 25: 18, 30: 18, 35: 19, 40: 19, 45: 20, 50: 21, 55: 21, 60: 22, 65: 22, 70: 23, 75: 24, 80: 24, 85: 25, 90: 26, 95: 26, 100: 27 }
const WBGT23 = { 20: 16, 25: 17, 30: 17, 35: 18, 40: 19, 45: 19, 50: 20, 55: 20, 60: 21, 65: 22, 70: 22, 75: 23, 80: 23, 85: 24, 90: 25, 95: 25, 100: 26 }
const WBGT22 = { 20: 16, 25: 16, 30: 17, 35: 17, 40: 18, 45: 18, 50: 19, 55: 19, 60: 20, 65: 21, 70: 21, 75: 22, 80: 22, 85: 23, 90: 24, 95: 24, 100: 25 }
const WBGT21 = { 20: 15, 25: 15, 30: 16, 35: 16, 40: 17, 45: 17, 50: 18, 55: 19, 60: 19, 65: 20, 70: 20, 75: 21, 80: 21, 85: 22, 90: 23, 95: 23, 100: 24 }

const WBGTLIST = {
    40: WBGT40,
    39: WBGT39,
    38: WBGT38,
    37: WBGT37,
    36: WBGT36,
    35: WBGT35,
    34: WBGT34,
    33: WBGT33,
    32: WBGT32,
    31: WBGT31,
    30: WBGT30,
    29: WBGT29,
    28: WBGT28,
    27: WBGT27,
    26: WBGT26,
    25: WBGT25,
    24: WBGT24,
    23: WBGT23,
    22: WBGT22,
    21: WBGT21
}

function getLastData(){
  var json = [
    {
      devid: 'abcdefg001',
      devname: 'テスト端末００１',
      timestamp: '2021-06-10 14:19:34',
      val01: 900,
      val03: 24,
      val04: 21,
      val02: 12,
      danmaxco2: 1000,
      danminco2: 0,
      warmaxco2: 800,
      warminco2: 0,
      danmaxair: 10,
      danminair: 0,
      warmaxair: 8,
      warminair: 0,
      danmaxtemp: 28,
      danmintemp: 0,
      warmaxtemp: 25,
      warmintemp: 0,
      danmaxhum: 28,
      danminhum: 0,
      warmaxhum: 25,
      warminhum: 0
    },
    {
      devid: 'abcdefg002',
      devname: 'テスト端末００２',
      timestamp: '2021-06-10 14:19:34',
      val01: 750,
      val03: 26,
      val04: 23,
      val02: 6,
      danmaxco2: 1000,
      danminco2: 0,
      warmaxco2: 800,
      warminco2: 0,
      danmaxair: 10,
      danminair: 0,
      warmaxair: 8,
      warminair: 0,
      danmaxtemp: 28,
      danmintemp: 0,
      warmaxtemp: 25,
      warmintemp: 0,
      danmaxhum: 28,
      danminhum: 0,
      warmaxhum: 25,
      warminhum: 0
    },
    {
      devid: 'abcdefg003',
      devname: 'テスト端末００３',
      timestamp: '2021-06-10 14:19:34',
      val01: 1100,
      val03: 23,
      val04: 28,
      val02: 10,
      danmaxco2: 1000,
      danminco2: 0,
      warmaxco2: 800,
      warminco2: 0,
      danmaxair: 10,
      danminair: 0,
      warmaxair: 8,
      warminair: 0,
      danmaxtemp: 28,
      danmintemp: 0,
      warmaxtemp: 25,
      warmintemp: 0,
      danmaxhum: 28,
      danminhum: 0,
      warmaxhum: 25,
      warminhum: 0
    },
    {
      devid: 'abcdefg004',
      devname: 'テスト端末００４',
      timestamp: '2021-06-10 14:19:34',
      val01: 678,
      val03: 23,
      val04: 21,
      val02: 7,
      danmaxco2: 1000,
      danminco2: 0,
      warmaxco2: 800,
      warminco2: 0,
      danmaxair: 10,
      danminair: 0,
      warmaxair: 8,
      warminair: 0,
      danmaxtemp: 28,
      danmintemp: 0,
      warmaxtemp: 25,
      warmintemp: 0,
      danmaxhum: 28,
      danminhum: 0,
      warmaxhum: 25,
      warminhum: 0
    },
    {
      devid: 'abcdefg005',
      devname: 'テスト端末００５',
      timestamp: '2021-06-10 14:19:34',
      val01: 789,
      val03: 28,
      val04: 25,
      val02: 7,
      danmaxco2: 1000,
      danminco2: 0,
      warmaxco2: 800,
      warminco2: 0,
      danmaxair: 10,
      danminair: 0,
      warmaxair: 8,
      warminair: 0,
      danmaxtemp: 28,
      danmintemp: 0,
      warmaxtemp: 25,
      warmintemp: 0,
      danmaxhum: 28,
      danminhum: 0,
      warmaxhum: 25,
      warminhum: 0
    },
    {
      devid: 'abcdefg006',
      devname: 'テスト端末００６',
      timestamp: '2021-06-10 14:19:34',
      val01: 632,
      val03: 22,
      val04: 25,
      val02: 8,
      danmaxco2: 1000,
      danminco2: 0,
      warmaxco2: 800,
      warminco2: 0,
      danmaxair: 10,
      danminair: 0,
      warmaxair: 8,
      warminair: 0,
      danmaxtemp: 28,
      danmintemp: 0,
      warmaxtemp: 25,
      warmintemp: 0,
      danmaxhum: 28,
      danminhum: 0,
      warmaxhum: 25,
      warminhum: 0
    },
    {
      devid: 'abcdefg007',
      devname: 'テスト端末００７',
      timestamp: '2021-06-10 14:19:34',
      val01: 678,
      val03: 23,
      val04: 21,
      val02: 7,
      danmaxco2: 1000,
      danminco2: 0,
      warmaxco2: 800,
      warminco2: 0,
      danmaxair: 10,
      danminair: 0,
      warmaxair: 8,
      warminair: 0,
      danmaxtemp: 28,
      danmintemp: 0,
      warmaxtemp: 25,
      warmintemp: 0,
      danmaxhum: 28,
      danminhum: 0,
      warmaxhum: 25,
      warminhum: 0
    },
    {
      devid: 'abcdefg008',
      devname: 'テスト端末００８',
      timestamp: '2021-06-10 14:19:34',
      val01: 678,
      val03: 27,
      val04: 29,
      val02: 11,
      danmaxco2: 1000,
      danminco2: 0,
      warmaxco2: 800,
      warminco2: 0,
      danmaxair: 10,
      danminair: 0,
      warmaxair: 8,
      warminair: 0,
      danmaxtemp: 28,
      danmintemp: 0,
      warmaxtemp: 25,
      warmintemp: 0,
      danmaxhum: 28,
      danminhum: 0,
      warmaxhum: 25,
      warminhum: 0
    },
    {
      devid: 'abcdefg009',
      devname: 'テスト端末００９',
      timestamp: '2021-06-10 14:19:34',
      val01: 678,
      val03: 23,
      val04: 21,
      val02: 7,
      danmaxco2: 1000,
      danminco2: 0,
      warmaxco2: 800,
      warminco2: 0,
      danmaxair: 10,
      danminair: 0,
      warmaxair: 8,
      warminair: 0,
      danmaxtemp: 28,
      danmintemp: 0,
      warmaxtemp: 25,
      warmintemp: 0,
      danmaxhum: 28,
      danminhum: 0,
      warmaxhum: 25,
      warminhum: 0
    },
    {
      devid: 'abcdefg010',
      devname: 'テスト端末０１０',
      timestamp: '2021-06-10 14:19:34',
      val01: 678,
      val03: 23,
      val04: 21,
      val02: 7,
      danmaxco2: 1000,
      danminco2: 0,
      warmaxco2: 800,
      warminco2: 0,
      danmaxair: 10,
      danminair: 0,
      warmaxair: 8,
      warminair: 0,
      danmaxtemp: 28,
      danmintemp: 0,
      warmaxtemp: 25,
      warmintemp: 0,
      danmaxhum: 28,
      danminhum: 0,
      warmaxhum: 25,
      warminhum: 0
    },
    {
      devid: 'abcdefg011',
      devname: 'テスト端末０１１',
      timestamp: '2021-06-10 14:19:34',
      val01: 678,
      val03: 40,
      val04: 21,
      val02: 7,
      danmaxco2: 1000,
      danminco2: 0,
      warmaxco2: 800,
      warminco2: 0,
      danmaxair: 10,
      danminair: 0,
      warmaxair: 8,
      warminair: 0,
      danmaxtemp: 28,
      danmintemp: 0,
      warmaxtemp: 25,
      warmintemp: 0,
      danmaxhum: 28,
      danminhum: 0,
      warmaxhum: 25,
      warminhum: 0
    },
  ];
  
  return json;
}

//リアルタイムモニタ
function getDayData(id){
  var json = '';
  
  if(id == 'abcdefg001'){
    json = [
      {
        devid: 'abcdefg001',
        devname: 'テスト端末００１',
        timestamp: '2021-06-10 14:19:34',
        val01: 900,
        val03: 24,
        val04: 21,
        val02: 12,
        danmaxco2: 1000,
        danminco2: 0,
        warmaxco2: 800,
        warminco2: 0,
        danmaxair: 10,
        danminair: 0,
        warmaxair: 8,
        warminair: 0,
        danmaxtemp: 28,
        danmintemp: 0,
        warmaxtemp: 25,
        warmintemp: 0,
        danmaxhum: 28,
        danminhum: 0,
        warmaxhum: 25,
        warminhum: 0,
        mailsend: 0
      },
      {
        devid: 'abcdefg001',
        devname: 'テスト端末００１',
        timestamp: '2021-06-10 14:09:34',
        val01: 850,
        val03: 28,
        val04: 20,
        val02: 8,
        danmaxco2: 1000,
        danminco2: 0,
        warmaxco2: 800,
        warminco2: 0,
        danmaxair: 10,
        danminair: 0,
        warmaxair: 8,
        warminair: 0,
        danmaxtemp: 28,
        danmintemp: 0,
        warmaxtemp: 25,
        warmintemp: 0,
        danmaxhum: 28,
        danminhum: 0,
        warmaxhum: 25,
        warminhum: 0,
        mailsend: 0
      },
      {
        devid: 'abcdefg001',
        devname: 'テスト端末００１',
        timestamp: '2021-06-10 13:59:34',
        val01: 1200,
        val03: 23,
        val04: 28,
        val02: 9,
        danmaxco2: 1000,
        danminco2: 0,
        warmaxco2: 800,
        warminco2: 0,
        danmaxair: 10,
        danminair: 0,
        warmaxair: 8,
        warminair: 0,
        danmaxtemp: 28,
        danmintemp: 0,
        warmaxtemp: 25,
        warmintemp: 0,
        danmaxhum: 28,
        danminhum: 0,
        warmaxhum: 25,
        warminhum: 0,
        mailsend: 0
      },
      {
        devid: 'abcdefg001',
        devname: 'テスト端末００１',
        timestamp: '2021-06-10 13:49:34',
        val01: 1105,
        val03: 26,
        val04: 26,
        val02: 7,
        danmaxco2: 1000,
        danminco2: 0,
        warmaxco2: 800,
        warminco2: 0,
        danmaxair: 10,
        danminair: 0,
        warmaxair: 8,
        warminair: 0,
        danmaxtemp: 28,
        danmintemp: 0,
        warmaxtemp: 25,
        warmintemp: 0,
        danmaxhum: 28,
        danminhum: 0,
        warmaxhum: 25,
        warminhum: 0,
        mailsend: 100
      },
      {
        devid: 'abcdefg001',
        devname: 'テスト端末００１',
        timestamp: '2021-06-10 13:39:34',
        val01: 998,
        val03: 23,
        val04: 21,
        val02: 10,
        danmaxco2: 1000,
        danminco2: 0,
        warmaxco2: 800,
        warminco2: 0,
        danmaxair: 10,
        danminair: 0,
        warmaxair: 8,
        warminair: 0,
        danmaxtemp: 28,
        danmintemp: 0,
        warmaxtemp: 25,
        warmintemp: 0,
        danmaxhum: 28,
        danminhum: 0,
        warmaxhum: 25,
        warminhum: 0,
        mailsend: 0
      }
    ];
  } else if(id == 'abcdefg002'){
    json = [
      {
        devid: 'abcdefg002',
        devname: 'テスト端末００２',
        timestamp: '2021-06-10 14:14:15',
        val01: 780,
        val03: 29,
        val04: 23,
        val02: 6,
        danmaxco2: 1000,
        danminco2: 0,
        warmaxco2: 800,
        warminco2: 0,
        danmaxair: 10,
        danminair: 0,
        warmaxair: 8,
        warminair: 0,
        danmaxtemp: 28,
        danmintemp: 0,
        warmaxtemp: 25,
        warmintemp: 0,
        danmaxhum: 28,
        danminhum: 0,
        warmaxhum: 25,
        warminhum: 0,
        mailsend: 0
      },
      {
        devid: 'abcdefg002',
        devname: 'テスト端末００２',
        timestamp: '2021-06-10 14:04:15',
        val01: 830,
        val03: 21,
        val04: 26,
        val02: 10,
        danmaxco2: 1000,
        danminco2: 0,
        warmaxco2: 800,
        warminco2: 0,
        danmaxair: 10,
        danminair: 0,
        warmaxair: 8,
        warminair: 0,
        danmaxtemp: 28,
        danmintemp: 0,
        warmaxtemp: 25,
        warmintemp: 0,
        danmaxhum: 28,
        danminhum: 0,
        warmaxhum: 25,
        warminhum: 0,
        mailsend: 100
      },
      {
        devid: 'abcdefg002',
        devname: 'テスト端末００２',
        timestamp: '2021-06-10 13:54:15',
        val01: 829,
        val03: 25,
        val04: 30,
        val02: 11,
        danmaxco2: 1000,
        danminco2: 0,
        warmaxco2: 800,
        warminco2: 0,
        danmaxair: 10,
        danminair: 0,
        warmaxair: 8,
        warminair: 0,
        danmaxtemp: 28,
        danmintemp: 0,
        warmaxtemp: 25,
        warmintemp: 0,
        danmaxhum: 28,
        danminhum: 0,
        warmaxhum: 25,
        warminhum: 0,
        mailsend: 0
      },
      {
        devid: 'abcdefg002',
        devname: 'テスト端末００２',
        timestamp: '2021-06-10 13:44:15',
        val01: 659,
        val03: 27,
        val04: 28,
        val02: 8,
        danmaxco2: 1000,
        danminco2: 0,
        warmaxco2: 800,
        warminco2: 0,
        danmaxair: 10,
        danminair: 0,
        warmaxair: 8,
        warminair: 0,
        danmaxtemp: 28,
        danmintemp: 0,
        warmaxtemp: 25,
        warmintemp: 0,
        danmaxhum: 28,
        danminhum: 0,
        warmaxhum: 25,
        warminhum: 0,
        mailsend: 0
      },
      {
        devid: 'abcdefg002',
        devname: 'テスト端末００２',
        timestamp: '2021-06-10 13:34:15',
        val01: 698,
        val03: 30,
        val04: 29,
        val02: 5,
        danmaxco2: 1000,
        danminco2: 0,
        warmaxco2: 800,
        warminco2: 0,
        danmaxair: 10,
        danminair: 0,
        warmaxair: 8,
        warminair: 0,
        danmaxtemp: 28,
        danmintemp: 0,
        warmaxtemp: 25,
        warmintemp: 0,
        danmaxhum: 28,
        danminhum: 0,
        warmaxhum: 25,
        warminhum: 0,
        mailsend: 0
      }
    ];
  } else if(id == 'abcdefg003'){
    json = [
      {
        devid: 'abcdefg003',
        devname: 'テスト端末００３',
        timestamp: '2021-06-10 14:23:44',
        val01: 870,
        val03: 24,
        val04: 29,
        val02: 9,
        danmaxco2: 1000,
        danminco2: 0,
        warmaxco2: 800,
        warminco2: 0,
        danmaxair: 10,
        danminair: 0,
        warmaxair: 8,
        warminair: 0,
        danmaxtemp: 28,
        danmintemp: 0,
        warmaxtemp: 25,
        warmintemp: 0,
        danmaxhum: 28,
        danminhum: 0,
        warmaxhum: 25,
        warminhum: 0,
        mailsend: 0
      },
      {
        devid: 'abcdefg003',
        devname: 'テスト端末００３',
        timestamp: '2021-06-10 14:13:44',
        val01: 987,
        val03: 26,
        val04: 24,
        val02: 10,
        danmaxco2: 1000,
        danminco2: 0,
        warmaxco2: 800,
        warminco2: 0,
        danmaxair: 10,
        danminair: 0,
        warmaxair: 8,
        warminair: 0,
        danmaxtemp: 28,
        danmintemp: 0,
        warmaxtemp: 25,
        warmintemp: 0,
        danmaxhum: 28,
        danminhum: 0,
        warmaxhum: 25,
        warminhum: 0,
        mailsend: 0
      },
      {
        devid: 'abcdefg003',
        devname: 'テスト端末００３',
        timestamp: '2021-06-10 14:03:44',
        val01: 1034,
        val03: 29,
        val04: 23,
        val02: 9,
        danmaxco2: 1000,
        danminco2: 0,
        warmaxco2: 800,
        warminco2: 0,
        danmaxair: 10,
        danminair: 0,
        warmaxair: 8,
        warminair: 0,
        danmaxtemp: 28,
        danmintemp: 0,
        warmaxtemp: 25,
        warmintemp: 0,
        danmaxhum: 28,
        danminhum: 0,
        warmaxhum: 25,
        warminhum: 0,
        mailsend: 0
      },
      {
        devid: 'abcdefg003',
        devname: 'テスト端末００３',
        timestamp: '2021-06-10 13:53:44',
        val01: 917,
        val03: 25,
        val04: 27,
        val02: 6,
        danmaxco2: 1000,
        danminco2: 0,
        warmaxco2: 800,
        warminco2: 0,
        danmaxair: 10,
        danminair: 0,
        warmaxair: 8,
        warminair: 0,
        danmaxtemp: 28,
        danmintemp: 0,
        warmaxtemp: 25,
        warmintemp: 0,
        danmaxhum: 28,
        danminhum: 0,
        warmaxhum: 25,
        warminhum: 0,
        mailsend: 0
      },
      {
        devid: 'abcdefg003',
        devname: 'テスト端末００３',
        timestamp: '2021-06-10 13:43:44',
        val01: 903,
        val03: 20,
        val04: 25,
        val02: 8,
        danmaxco2: 1000,
        danminco2: 0,
        warmaxco2: 800,
        warminco2: 0,
        danmaxair: 10,
        danminair: 0,
        warmaxair: 8,
        warminair: 0,
        danmaxtemp: 28,
        danmintemp: 0,
        warmaxtemp: 25,
        warmintemp: 0,
        danmaxhum: 28,
        danminhum: 0,
        warmaxhum: 25,
        warminhum: 0,
        mailsend: 100
      }
    ];
  }
  
  return json;
}

//データ
function getDayDataEx(id, date){
  var json = '';
  
  if(id == 'abcdefg001'){
    if(date == '2021-06-29'){
      json = [
        {
          devid: 'abcdefg001',
          devname: 'テスト端末００１',
          timestamp: '2021-06-29 14:19:34',
          val01: 900,
          val03: 24,
          val04: 21,
          val02: 12,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        },
        {
          devid: 'abcdefg001',
          devname: 'テスト端末００１',
          timestamp: '2021-06-29 14:09:34',
          val01: 850,
          val03: 28,
          val04: 20,
          val02: 8,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        },
        {
          devid: 'abcdefg001',
          devname: 'テスト端末００１',
          timestamp: '2021-06-29 13:59:34',
          val01: 1200,
          val03: 23,
          val04: 28,
          val02: 9,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        },
        {
          devid: 'abcdefg001',
          devname: 'テスト端末００１',
          timestamp: '2021-06-29 13:49:34',
          val01: 1045,
          val03: 26,
          val04: 26,
          val02: 7,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 100
        },
        {
          devid: 'abcdefg001',
          devname: 'テスト端末００１',
          timestamp: '2021-06-29 13:39:34',
          val01: 918,
          val03: 23,
          val04: 21,
          val02: 10,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        }
      ];
    }else if(date == '2021-06-30'){
      json = [
        {
          devid: 'abcdefg001',
          devname: 'テスト端末００１',
          timestamp: '2021-06-30 14:19:25',
          val01: 798,
          val03: 25,
          val04: 21,
          val02: 9,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        },
        {
          devid: 'abcdefg001',
          devname: 'テスト端末００１',
          timestamp: '2021-06-30 14:09:14',
          val01: 698,
          val03: 23,
          val04: 24,
          val02: 8,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        },
        {
          devid: 'abcdefg001',
          devname: 'テスト端末００１',
          timestamp: '2021-06-30 13:59:24',
          val01: 987,
          val03: 28,
          val04: 25,
          val02: 11,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        },
        {
          devid: 'abcdefg001',
          devname: 'テスト端末００１',
          timestamp: '2021-06-30 13:49:14',
          val01: 1105,
          val03: 28,
          val04: 23,
          val02: 8,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 100
        },
        {
          devid: 'abcdefg001',
          devname: 'テスト端末００１',
          timestamp: '2021-06-30 13:39:04',
          val01: 998,
          val03: 29,
          val04: 20,
          val02: 6,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 100
        }
      ];
    }
  }else if(id == 'abcdefg002'){
    if(date == '2021-06-29'){
      json = [
        {
          devid: 'abcdefg002',
          devname: 'テスト端末００２',
          timestamp: '2021-06-29 14:19:34',
          val01: 850,
          val03: 22,
          val04: 21,
          val02: 11,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        },
        {
          devid: 'abcdefg002',
          devname: 'テスト端末００２',
          timestamp: '2021-06-29 14:09:34',
          val01: 798,
          val03: 22,
          val04: 24,
          val02: 9,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        },
        {
          devid: 'abcdefg002',
          devname: 'テスト端末００２',
          timestamp: '2021-06-29 13:59:34',
          val01: 1101,
          val03: 28,
          val04: 21,
          val02: 11,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        },
        {
          devid: 'abcdefg002',
          devname: 'テスト端末００２',
          timestamp: '2021-06-29 13:49:34',
          val01: 999,
          val03: 27,
          val04: 23,
          val02: 6,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 100
        },
        {
          devid: 'abcdefg002',
          devname: 'テスト端末００２',
          timestamp: '2021-06-29 13:39:34',
          val01: 888,
          val03: 24,
          val04: 24,
          val02: 8,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        }
      ];
    }else if(date == '2021-06-30'){
      json = [
        {
          devid: 'abcdefg002',
          devname: 'テスト端末００２',
          timestamp: '2021-06-30 14:19:25',
          val01: 777,
          val03: 25,
          val04: 24,
          val02: 10,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 100
        },
        {
          devid: 'abcdefg002',
          devname: 'テスト端末００２',
          timestamp: '2021-06-30 14:09:14',
          val01: 734,
          val03: 26,
          val04: 24,
          val02: 8,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        },
        {
          devid: 'abcdefg002',
          devname: 'テスト端末００２',
          timestamp: '2021-06-30 13:59:24',
          val01: 987,
          val03: 26,
          val04: 21,
          val02: 10,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        },
        {
          devid: 'abcdefg002',
          devname: 'テスト端末００２',
          timestamp: '2021-06-30 13:49:14',
          val01: 1001,
          val03: 22,
          val04: 26,
          val02: 9,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 100
        },
        {
          devid: 'abcdefg002',
          devname: 'テスト端末００２',
          timestamp: '2021-06-30 13:39:04',
          val01: 1006,
          val03: 25,
          val04: 25,
          val02: 8,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        }
      ];
    }
  }else if(id == 'abcdefg003'){
    if(date == '2021-06-29'){
      json = [
        {
          devid: 'abcdefg003',
          devname: 'テスト端末００３',
          timestamp: '2021-06-29 14:19:34',
          val01: 999,
          val03: 26,
          val04: 19,
          val02: 10,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        },
        {
          devid: 'abcdefg003',
          devname: 'テスト端末００３',
          timestamp: '2021-06-29 14:09:34',
          val01: 812,
          val03: 25,
          val04: 22,
          val02: 8,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 100
        },
        {
          devid: 'abcdefg003',
          devname: 'テスト端末００３',
          timestamp: '2021-06-29 13:59:34',
          val01: 1067,
          val03: 24,
          val04: 24,
          val02: 12,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        },
        {
          devid: 'abcdefg003',
          devname: 'テスト端末００３',
          timestamp: '2021-06-29 13:49:34',
          val01: 871,
          val03: 23,
          val04: 29,
          val02: 9,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 100
        },
        {
          devid: 'abcdefg003',
          devname: 'テスト端末００３',
          timestamp: '2021-06-29 13:39:34',
          val01: 948,
          val03: 25,
          val04: 23,
          val02: 9,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        }
      ];
    }else if(date == '2021-06-30'){
      json = [
        {
          devid: 'abcdefg003',
          devname: 'テスト端末００３',
          timestamp: '2021-06-30 14:19:25',
          val01: 630,
          val03: 30,
          val04: 28,
          val02: 10,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 100
        },
        {
          devid: 'abcdefg003',
          devname: 'テスト端末００３',
          timestamp: '2021-06-30 14:09:14',
          val01: 987,
          val03: 27,
          val04: 21,
          val02: 9,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        },
        {
          devid: 'abcdefg003',
          devname: 'テスト端末００３',
          timestamp: '2021-06-30 13:59:24',
          val01: 935,
          val03: 24,
          val04: 25,
          val02: 11,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        },
        {
          devid: 'abcdefg003',
          devname: 'テスト端末００３',
          timestamp: '2021-06-30 13:49:14',
          val01: 782,
          val03: 28,
          val04: 26,
          val02: 6,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        },
        {
          devid: 'abcdefg003',
          devname: 'テスト端末００３',
          timestamp: '2021-06-30 13:39:04',
          val01: 832,
          val03: 26,
          val04: 27,
          val02: 10,
          danmaxco2: 1000,
          danminco2: 0,
          warmaxco2: 800,
          warminco2: 0,
          danmaxair: 10,
          danminair: 0,
          warmaxair: 8,
          warminair: 0,
          danmaxtemp: 28,
          danmintemp: 0,
          warmaxtemp: 25,
          warmintemp: 0,
          danmaxhum: 28,
          danminhum: 0,
          warmaxhum: 25,
          warminhum: 0,
          mailsend: 0
        }
      ];
    }
  }
  
  return json;
}

function getWBGTData(temp, hum){
  var param_temp = ChangeTempParam(temp);
  var param_hum = ChangeHumParam(hum);
  var param_wbgt = parseFloat(WBGTLIST[param_temp][param_hum]);
  return param_wbgt;
}

function ChangeTempParam(temp) {
    var ret = temp;
    if (40 < ret) {
        ret = 40;
    } else if (21 > ret) {
        ret = 21
    }

    return Math.floor(ret);
}

function ChangeHumParam(hum) {
    var ret = hum;

    if (hum < 20.0) {
        ret = 20;
    } else if (hum > 20.0 && hum < 25.0) {
        ret = 20;
    } else if (hum > 25.0 && hum < 30.0) {
        ret = 25;
    } else if (hum > 30.0 && hum < 35.0) {
        ret = 30;
    } else if (hum > 35.0 && hum < 40.0) {
        ret = 35;
    } else if (hum > 40.0 && hum < 45.0) {
        ret = 40;
    } else if (hum > 45.0 && hum < 50.0) {
        ret = 45;
    } else if (hum > 50.0 && hum < 55.0) {
        ret = 50;
    } else if (hum > 55.0 && hum < 60.0) {
        ret = 55;
    } else if (hum > 60.0 && hum < 65.0) {
        ret = 60;
    } else if (hum > 65.0 && hum < 70.0) {
        ret = 65;
    } else if (hum > 70.0 && hum < 75.0) {
        ret = 70;
    } else if (hum > 75.0 && hum < 80.0) {
        ret = 75;
    } else if (hum > 80.0 && hum < 85.0) {
        ret = 80;
    } else if (hum > 85.0 && hum < 90.0) {
        ret = 85;
    } else if (hum > 90.0 && hum < 95.0) {
        ret = 90;
    } else if (hum > 95.0 && hum < 100.0) {
        ret = 95;
    } else if (hum > 100.0) {
        ret = 100;
    }

    return Math.floor(ret);
}

export default {
  
  getLastData,
  getDayData,
  getDayDataEx,
  getWBGTData
};